import React, { Fragment, useState, useContext } from "react";
import { ShoppingCartContext } from "../ShoppingCartLogic";
import TermsAndConditions from "./Checkout-Terms";
import InstructionCard from "./Checkout-InstructionCard";
import ShoppingCartCard from "./Checkout-ShoppingCartCard";
import { BtnBack } from "./Buttons";

const CheckoutRoute = props => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { contact, loggedInAs } = props;
  const value = useContext(ShoppingCartContext);
  const { itemsInCart, subtotal, removeFromCart } = value;
  const handleTermsAccepted = () => {
    // Resets the scroll to the top of the page.
    window.scrollTo(0, 0);
    setTermsAccepted(!termsAccepted);
  };
  return (
    <Fragment>
      <BtnBack history={props.history} />
      {!termsAccepted ? (
        <TermsAndConditions continue={() => handleTermsAccepted()} />
      ) : (
        <div className="wrap-checkoutPage">
          <InstructionCard loggedInAs={loggedInAs} contact={contact} />
          <ShoppingCartCard
            loggedInAs={loggedInAs}
            itemsInCart={itemsInCart}
            subtotal={subtotal}
            removeFromCart={id => removeFromCart(id)}
          />
        </div>
      )}
    </Fragment>
  );
};
export default CheckoutRoute;
