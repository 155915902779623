import React, { Component } from 'react';
import Loader from './views/Loader';
import endOfToday from 'date-fns/end_of_today';
import differenceInDays from 'date-fns/difference_in_days';
import { postLifespan, calculateDiscount } from './helpers';
import { client } from './sanityClient';

export default class QueryData extends Component {
  state = {};

  componentDidMount() {
    client
      .fetch('*[_type in ["product", "contact"]] | order(price asc)')
      .then(res => {
        const contact = res.find(item => item._type === 'contact');
        const products = res.filter(item => item._type === 'product');
        this.setState({ contact, products });
      })
      .catch(err => {
        console.error('Uhoh! There was a problem fetching product data: ', err);
      });
  }

  render() {
    const { products, contact } = this.state;
    if (products) {
      // Augment the products that are queried from the database
      // and save them back into a variable called augmentedProducts.
      const augmentedProducts = products.map(product => {
        // ** Date logic ** //
        // differenceInDays takes two arguments. The first should be the later date.
        const postUptime = differenceInDays(
          endOfToday(),
          product.customPostedDate || product._createdAt
        );
        const daysRemaining = postLifespan - postUptime;
        const onSale = daysRemaining <= 5 ? true : false;
        const expired = daysRemaining <= 0 ? true : false;

        // ** Pricing logic ** //
        const salePrice = calculateDiscount(product.price);

        // Add new values to the product object
        const updatedProduct = {
          ...product,
          daysRemaining,
          onSale,
          expired,
          salePrice
        };

        return updatedProduct;
      });

      // Remove products that have expired or that aren't published
      const activeProducts = augmentedProducts.filter(product => (product.expired ? false : true));

      const allData = { products: activeProducts, contact };

      return this.props.render(allData);
    } else {
      return <Loader />;
    }
  }
}
