/* eslint react-hooks/exhaustive-deps: 0 */
import React, { createContext, useState, useEffect } from 'react';

// Context can be accessed from anywhere in the app.
export const ShoppingCartContext = createContext();

const ShoppingCartProvider = ({ products, ...props }) => {
  const [itemsInCart, setItemsInCart] = useState([]);

  const populateCart = () => {
    // This grabs the keys for everything in local storage.
    const keysInLocalStorage = Object.keys(localStorage);
    // This compares those keys to all products and returns product data for products in cart.
    const productsFilteredByKey = products.filter(product =>
      keysInLocalStorage.find(key => key === product._id)
    );
    // This works like setState. When called it re-renders the component showing the new state.
    setItemsInCart(productsFilteredByKey);
  };

  // This automatically runs populateCart once after the component mounts.
  useEffect(() => populateCart(), []);

  const addToCart = product => {
    localStorage.setItem(product._id, JSON.stringify(product));
    populateCart();
  };

  const removeFromCart = id => {
    localStorage.removeItem(id);
    // Running populateCart causes a re-render of the component with the new state.
    populateCart();
  };

  // Add up the subtotal.
  const calculateSubtotal = itemsInCart.reduce(
    (accumulator, item) => accumulator + (item.onSale ? item.salePrice : item.price),
    0
  );

  return (
    <ShoppingCartContext.Provider
      value={{
        itemsInCart,
        addToCart,
        removeFromCart,
        subtotal: calculateSubtotal
      }}
    >
      {props.children}
    </ShoppingCartContext.Provider>
  );
};

export default ShoppingCartProvider;
