import { sanityProduction } from './keys'; // Use in production
// import { sanityTestEnv } from './keys';  // Use if testing
import imageUrlBuilder from '@sanity/image-url';
const sanityClient = require('@sanity/client');

// This is the config for communicating with Sanity.io
export const client = sanityClient({
  projectId: sanityProduction.projectId, // Use in production
  dataset: sanityProduction.dataset, // Use in production
  // projectId: sanityTestEnv.projectId,  // Use if testing
  // dataset: sanityTestEnv.dataset,      // Use if testing
  useCdn: false // `false` if you want to ensure fresh data
  // token: 'sanity-auth-token', // or leave blank to be anonymous user
});

// This handles grabbing the requested images from Sanity.io
// Read about it: https://www.sanity.io/docs/presenting-images
const imageBuilder = imageUrlBuilder(client);
export const urlForImg = source => imageBuilder.image(source);
