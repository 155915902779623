import React, { Fragment } from "react";
import ProductImageGrid from "./Product-ImageGrid";
import ProductInfoCard from "./Product-InfoCard";
import { BtnBack } from "./Buttons";

const ProductPage = props => {
  // Find the product whose id matches the id in the current page url.
  const product = props.products.find(product => product._id === props.match.params.id);
  return (
    <Fragment>
      <BtnBack history={props.history} />
      <div className="wrap-productPage">
        <ProductImageGrid product={product} />
        <ProductInfoCard product={product} />
      </div>
    </Fragment>
  );
};

export default ProductPage;
