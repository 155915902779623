import React, { Fragment, useContext } from 'react';
import { ShoppingCartContext } from '../ShoppingCartLogic';
import { withRouter } from 'react-router-dom';
import { Spring, animated } from 'react-spring/renderprops';
import { formatPrice } from '../helpers';
// import useItemsInCart from "../useItemsInCart";

const ProductInfoCard = props => {
  const { product } = props;
  const value = useContext(ShoppingCartContext);
  const { itemsInCart, addToCart, removeFromCart } = value;

  const addToCartAndCheckout = () => {
    addToCart(product);
    props.history.push('/checkout');
  };

  // Check to see if the current product is already in the user's cart. Evaluates to truthy or falsy.
  let productIsInCart = itemsInCart.filter(item => item._id === product._id).length;

  return (
    <Spring
      native
      config={{ tension: 500, friction: 50, delay: 160 }}
      from={{ opacity: 0, y: 10 }}
      to={{ opacity: 1, y: 0 }}
    >
      {({ opacity, y }) => (
        <animated.div
          className="ctr-productInfo"
          style={{ opacity, transform: y.interpolate(y => `translate3d(0,${y}px,0)`) }}
        >
          <div className="card__productInfo card">
            <div className="accentBar" />
            <section>
              <h2>{product.product}</h2>
              <p className="margin">{product.engine}</p>
            </section>
            <hr />
            <section>
              <h4>Hours on engine</h4>
              <p>{product.hours}</p>
            </section>
            <section>
              <h4>Model number</h4>
              <p>{product.modelNumber}</p>
            </section>
            <section>
              <h4>Serial number</h4>
              <p>{product.serialNumber}</p>
            </section>
            <section>
              <h4>Condition</h4>
              <p>{product.condition}</p>
            </section>
          </div>
          <div className="card card__daysRemaining">
            <svg
              className="material-icon"
              style={{ fill: `${product.onSale && '#FC5F6B'}` }}
              viewBox="0 0 24 24"
            >
              <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z" />
              <path fill="none" d="M0 0h24v24H0z" />
            </svg>
            <p className="displayNoneOnGrid" style={{ color: `${product.onSale && '#FC5F6B'}` }}>
              {`Listing expires in ${product.daysRemaining} ${
                product.daysRemaining === 1 ? 'day' : 'days'
              }`}
            </p>
          </div>
          <div className="card card__price">
            <Price price={product.price} onSale={product.onSale} salePrice={product.salePrice} />
            <Fragment>
              {productIsInCart ? (
                <button
                  onClick={() => removeFromCart(product._id)}
                  className="btn--secondary btn--lg w--100"
                >
                  Remove from cart
                </button>
              ) : (
                <button
                  onClick={() => addToCart(product)}
                  className="btn--secondary btn--lg w--100"
                >
                  Add to cart
                </button>
              )}
              <button
                onClick={() => addToCartAndCheckout()}
                className="btn--primary btn--lg w--100"
                style={{ marginTop: '1rem' }}
              >
                {productIsInCart ? 'Checkout' : 'Add to cart & checkout'}
              </button>
            </Fragment>
          </div>
        </animated.div>
      )}
    </Spring>
  );
};

export default withRouter(ProductInfoCard);

const Price = props => {
  const { price, onSale, salePrice } = props;
  return onSale ? (
    <Fragment>
      <p className="price">{`${formatPrice(salePrice)}*`}</p>
      <section>
        <div className="priceReducedIndicator" />
        <p className="lineThrough">{`Was ${formatPrice(price)}`}</p>
      </section>
      <div className="caption">*plus tax (tax applies to Excel employees only)</div>
    </Fragment>
  ) : (
    <Fragment>
      <p className="specs--md price">{`${formatPrice(price)}*`}</p>
      <div className="caption">*plus tax (tax applies to Excel employees only)</div>
    </Fragment>
  );
};
