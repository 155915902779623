import React, { Fragment, useState } from 'react';
import { Spring, animated } from 'react-spring/renderprops';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ShoppingCartCard = props => {
  const { loggedInAs, contact } = props;
  return (
    <Spring
      native
      config={{ tension: 500, friction: 50 }}
      from={{ opacity: 0, y: 10 }}
      to={{ opacity: 1, y: 0 }}
    >
      {({ opacity, y }) => (
        <animated.div
          className="card card__purchaseInstructions"
          style={{ opacity, transform: y.interpolate(y => `translate3d(0,${y}px,0)`) }}
        >
          <div className="accentBar" />
          {loggedInAs === 'employee' ? <EmployeeInfo contact={contact} /> : <DealerInfo />}
        </animated.div>
      )}
    </Spring>
  );
};

export default ShoppingCartCard;

const EmployeeInfo = props => {
  const [copiedEmail, setCopiedEmail] = useState(false);
  const { contact } = props;
  return (
    <Fragment>
      <section>
        <h2>Employee Purchases</h2>
        <p>
          To make a purchase, contact {contact.fullName} with the serial number for the product(s)
          you’ve selected.
        </p>
      </section>
      <section>
        <h4>{contact.fullName}</h4>
        <a href={`tel:${contact.phone}`}>
          <button className="btn--secondary w--100">
            <p>{contact.phone}</p>
          </button>
        </a>
        <CopyToClipboard text={contact.email} onCopy={() => setCopiedEmail(!copiedEmail)}>
          <button className="btn--secondary w--100">
            {copiedEmail ? <p>Email address copied.</p> : <p>{contact.email}</p>}
            <svg className="material-icon" viewBox="0 0 24 24">
              <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
            </svg>
          </button>
        </CopyToClipboard>
      </section>
      <section>
        <h4>Transportation</h4>
        <p>
          All transportation of machines purchased by internal employees is the responsibility of
          the purchaser. All sales to employees take place in Kansas.
        </p>
      </section>
    </Fragment>
  );
};

const DealerInfo = () => (
  <Fragment>
    <section>
      <h2>Dealer Purchases</h2>
      <p>
        Make your order using normal wholegoods order entry procedures. At time of order, please
        specify the Serial Number and Model Number and that this is a unit being purchased from the
        Excel Marketplace.
      </p>
    </section>
    <hr />
    <section>
      <h4>Freight policy</h4>
      <p>
        All transportation of machines purchased by dealers will be subject to our published freight
        policy:
      </p>
      <p>$15,000 or greater is free freight, $250 freight charge for all orders under $15,000</p>
    </section>
  </Fragment>
);
