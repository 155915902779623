import React, { useState } from 'react';
import logo from '../img/marketplaceLogo.svg';
import { Spring, animated } from 'react-spring/renderprops';
import { withRouter } from 'react-router-dom';

const Login = props => {
  const [inputValue, updateInputValue] = useState('Password');
  const [inputType, updateInputType] = useState('text');
  const [inputError, setInputError] = useState(false);

  const handleFocus = () => {
    updateInputType('password');
    if (inputValue === 'Password') {
      updateInputValue('');
      setInputError(false);
    }
  };

  const handleBlur = () => {
    if (inputValue === '') {
      updateInputValue('Password');
      updateInputType('text');
    }
  };

  const validatePassword = () => {
    let value = inputValue.toLowerCase();
    if (value === 'dealer') {
      sessionStorage.setItem('loggedInAs', 'dealer');
      props.history.push('/');
    } else if (value === 'employee') {
      sessionStorage.setItem('loggedInAs', 'employee');
      props.history.push('/');
    } else if (value === 'admin') {
      sessionStorage.setItem('loggedInAs', 'admin');
      props.history.push('/');
    } else if (value === 'bigdog') {
      sessionStorage.setItem('loggedInAs', 'bigdog');
      props.history.push('/');
    } else {
      setInputError(true);
    }
  };

  const loggedInAs = sessionStorage.getItem('loggedInAs');

  const styleInputError = {
    border: '1px solid #FC5F6B',
    boxShadow: '0 4px 20px -2px hsla(355, 62%, 60%, 0.3)'
  };

  if (
    loggedInAs === 'dealer' ||
    loggedInAs === 'employee' ||
    loggedInAs === 'admin' ||
    loggedInAs === 'bigdog'
  ) {
    return props.render(loggedInAs);
  } else {
    return (
      <div className="wrap-loginPage">
        <Spring
          native
          config={{ tension: 500, friction: 50, delay: 160 }}
          from={{ opacity: 0, y: 10 }}
          to={{ opacity: 1, y: 0 }}
        >
          {({ opacity, y }) => (
            <animated.div
              className="loginCard card"
              style={{ opacity, transform: y.interpolate(y => `translate3d(0,${y}px,0)`) }}
            >
              <div className="accentBar" />
              <div className="ctr-logo">
                <img src={logo} className="appLogo" alt="logo" />
                <h1>Excel Marketplace</h1>
              </div>
              <input
                type={inputType}
                className="passwordInput"
                style={inputError ? styleInputError : null}
                value={inputValue}
                onFocus={handleFocus}
                onChange={e => updateInputValue(e.target.value)}
                onBlur={handleBlur}
                onKeyDown={e => e.keyCode === 13 && validatePassword()}
              />
              <button onClick={() => validatePassword()} className="btn--primary btn--lg w--100">
                Login
              </button>
            </animated.div>
          )}
        </Spring>
      </div>
    );
  }
};

export default withRouter(Login);
