import React from 'react';
import { Spring, animated } from 'react-spring/renderprops';
import ProductCard from './AllProducts-Card';

const AllProductsPage = ({ products, loggedInAs }) => {
  // Handles what brand of products to show depending on how the user logged in.
  let relevantProducts;
  if (loggedInAs === 'bigdog') {
    relevantProducts = products.filter(product => product.brand === 'bigdog');
  } else if (loggedInAs === 'dealer') {
    relevantProducts = products.filter(product => product.brand === 'hustler');
  } else {
    relevantProducts = products;
  }

  // If relevant products exist show them.
  if (relevantProducts.length) {
    return (
      <Spring
        native
        config={{ tension: 500, friction: 50, delay: 160 }}
        from={{ opacity: 0, y: 10 }}
        to={{ opacity: 1, y: 0 }}
      >
        {({ opacity, y }) => (
          <animated.div
            className="productGrid"
            style={{ opacity, transform: y.interpolate(y => `translate3d(0,${y}px,0)`) }}
          >
            {relevantProducts.map(product => (
              <ProductCard product={product} key={product._id} loggedInAs={loggedInAs} />
            ))}
          </animated.div>
        )}
      </Spring>
    );
  } else {
    return (
      <div className="noAvailableProductsMsg">
        <h2>There are currently no available products.</h2>
        <h4>Please check back at a later time.</h4>
      </div>
    );
  }
};

export default AllProductsPage;
