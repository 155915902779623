import React, { Fragment } from 'react';
import { Spring, animated } from 'react-spring/renderprops';
import { urlForImg } from '../sanityClient';
import { formatPrice } from '../helpers';
import { hesstonSalesTax } from '../helpers';

const ShoppingCartCard = props => {
  const { itemsInCart, subtotal, loggedInAs } = props;
  return (
    <Spring
      native
      config={{ tension: 500, friction: 50, delay: 100 }}
      from={{ opacity: 0, y: 10 }}
      to={{ opacity: 1, y: 0 }}
    >
      {({ opacity, y }) => (
        <animated.div
          className="card card__shoppingCart"
          style={{ opacity, transform: y.interpolate(y => `translate3d(0,${y}px,0)`) }}
        >
          <div className="accentBar" />
          <h2>Shopping Cart</h2>
          <hr />
          {itemsInCart.length ? (
            <ListItemsInCart
              itemsInCart={itemsInCart}
              removeFromCart={id => props.removeFromCart(id)}
            />
          ) : (
            <div className="ctr-cartEmpty">
              <h2>Your cart is empty</h2>
            </div>
          )}
          <hr />
          {loggedInAs === 'employee' ? (
            <EmployeeTotals subtotal={subtotal} />
          ) : (
            <DealerTotals dealerTotal={subtotal} />
          )}
        </animated.div>
      )}
    </Spring>
  );
};

export default ShoppingCartCard;

const ListItemsInCart = props =>
  props.itemsInCart.map((product, i) => (
    <div className="cartItem" key={i}>
      <div className="ctr-cartItem-left">
        <div
          className="productImage"
          role="img"
          aria-label={product.product}
          style={{ backgroundImage: `url(${urlForImg(product.photos[0])})` }}
        />
        <div className="ctr-productInfo">
          <h4>{product.product}</h4>
          <p>Model: {product.modelNumber}</p>
          <p>Serial: {product.serialNumber}</p>
        </div>
      </div>
      <div className="ctr-productPrice">
        {product.onSale ? (
          <Fragment>
            <h4>{formatPrice(product.salePrice)}</h4>
            <p className="lineThrough">{formatPrice(product.price)}</p>
          </Fragment>
        ) : (
          <h4>{formatPrice(product.price)}</h4>
        )}
      </div>
      <div onClick={() => props.removeFromCart(product._id)} className="btn--remove">
        <svg className="material-icon" viewBox="0 0 24 24">
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      </div>
    </div>
  ));

const EmployeeTotals = props => {
  const { subtotal } = props;
  const tax = hesstonSalesTax(subtotal);
  return (
    <Fragment>
      <div className="ctr-taxAndSubtotal">
        <div className="line">
          <p>Subtotal:</p>
          <p>{formatPrice(subtotal)}</p>
        </div>
        <div className="line">
          <p>Estimated Tax:</p>
          <p>{formatPrice(tax)}*</p>
        </div>
      </div>
      <div className="ctr-total line colorPrimary">
        <h2>Estimated Total:</h2>
        <h2>{formatPrice(subtotal + tax)}*</h2>
      </div>
      <hr />
      <div className="caption">
        *Sale is not based on these exact figures. This is solely an estimate.
      </div>
    </Fragment>
  );
};

const DealerTotals = props => {
  return (
    <Fragment>
      <div className="ctr-total line colorPrimary">
        <h2>Estimated Total:</h2>
        <h2>{formatPrice(props.dealerTotal)}*</h2>
      </div>
      <hr />
      <div className="caption">
        *Sale is not based on these exact figures. This is solely an estimate. Estimate does not
        factor in freight.
      </div>
    </Fragment>
  );
};
