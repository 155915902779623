import React from "react";

const Loader = () => (
  <div className="wrap-loader">
    <div className="ctr-loader">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Loader;
