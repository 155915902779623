import React, { Fragment } from "react";
// Using HashRouter instead of BrowserRouter here.
// Why?: https://github.com/ReactTraining/react-router/blob/master/FAQ.md#why-doesnt-my-application-render-after-refreshing
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Nav from "./views/Nav";
import AllProductsPage from "./views/AllProducts-Page";
import ProductPage from "./views/Product-Page";
import CheckoutRoute from "./views/Checkout-Route";
import Login from "./views/Login";
import ShoppingCartProvider from "./ShoppingCartLogic";

const App = props => {
  const { products, contact } = props.data;
  return (
    <Router>
      <ScrollToTop>
        <ShoppingCartProvider products={products}>
          <Login
            render={loggedInAs => (
              <Fragment>
                <Nav />
                <main>
                  <Switch>
                    {/* In order to pass props down through a route I had to use a render prop here */}
                    <Route
                      exact
                      path="/"
                      render={routeProps => (
                        <AllProductsPage
                          {...routeProps}
                          products={products}
                          loggedInAs={loggedInAs}
                        />
                      )}
                    />
                    <Route
                      path="/product/:id"
                      render={routeProps => <ProductPage {...routeProps} products={products} />}
                    />
                    <Route
                      path="/checkout"
                      render={routeProps => (
                        <CheckoutRoute {...routeProps} contact={contact} loggedInAs={loggedInAs} />
                      )}
                    />
                  </Switch>
                </main>
              </Fragment>
            )}
          />
        </ShoppingCartProvider>
      </ScrollToTop>
    </Router>
  );
};

export default App;
