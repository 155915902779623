import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Spring, animated } from 'react-spring/renderprops';
import { formatPrice } from '../helpers';

const CartDropdown = props => {
  const { toggle, itemsInCart, removeFromCart, subtotal } = props;
  return (
    <Spring
      native
      config={{ tension: 1000, friction: 46 }}
      from={{ opacity: 0, scale: 0.8 }}
      to={{ opacity: 1, scale: 1 }}
    >
      {({ opacity, scale }) => (
        <Fragment>
          <animated.div
            className="cartDropdown card"
            style={{
              opacity,
              transform: scale.interpolate(scale => `scale3d(${scale},${scale},${scale})`),
              transformOrigin: 'top right'
            }}
          >
            <h4>Shopping cart</h4>
            <hr />
            {/* If there are items in the cart display items otherwise say cart is empty */}
            {itemsInCart.length ? (
              <CartBody
                itemsInCart={itemsInCart}
                subtotal={subtotal}
                handleRemoval={id => removeFromCart(id)}
                toggle={toggle}
              />
            ) : (
              <div className="ctr-cartEmpty">
                <h4>Your cart is empty</h4>
              </div>
            )}
          </animated.div>
          {/* An invisible fullscreen element which toggles the cart off when clicked */}
          <div
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            }}
            onClick={toggle}
          />
        </Fragment>
      )}
    </Spring>
  );
};
export default CartDropdown;

//

const CartBody = props => (
  <div className="ctr-cartBody">
    {props.itemsInCart.map((product, i) => (
      <CartItem product={product} key={i} handleRemoval={id => props.handleRemoval(id)} />
    ))}
    <hr />
    <div className="subtotal">
      <p>Subtotal:</p>
      <p>{formatPrice(props.subtotal)}</p>
    </div>
    <hr />
    <Link to="/checkout" onClick={props.toggle}>
      <button className="btn--primary btn--lg w--100">Checkout</button>
    </Link>
  </div>
);

//

const CartItem = props => {
  const { product, onSale, price, salePrice, _id } = props.product;
  return (
    <div className="cartItem">
      <p>{product}</p>
      {/* If the product is on sale then show sale price */}
      <p>{onSale ? formatPrice(salePrice) : formatPrice(price)}</p>
      <div onClick={() => props.handleRemoval(_id)} className="btn--remove">
        <svg className="material-icon" viewBox="0 0 24 24">
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      </div>
    </div>
  );
};
