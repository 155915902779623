import React from "react";

export const BtnFilter = props => (
  <button onClick={props.handleClick} className="btn--contextual">
    <svg className="material-icon" viewBox="0 0 24 24">
      <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
    </svg>
    <span>Filter products</span>
  </button>
);

export const BtnBack = props => (
  <button onClick={props.history.goBack} className="btn--contextual">
    <svg className="material-icon" viewBox="0 0 24 24">
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
    </svg>
    <span>Back</span>
  </button>
);
