// Number of days before a product listing should expire.
export const postLifespan = 30;

// Reduce price by 10% then round removing the decimal.
export const calculateDiscount = price => Math.round(price - price * 0.1);

// Calculate the Hesston sales tax.
export const hesstonSalesTax = price => Math.round(price * 0.085);

// Turns price to string and includes commas
export const formatPrice = price => `$${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
