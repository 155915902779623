import React from 'react';
import { urlForImg } from '../sanityClient';
import { Trail, animated } from 'react-spring/renderprops';

const ProductImageGrid = props => (
  <div className="imageGrid">
    <Trail
      native
      items={props.product.photos}
      keys={photo => photo._key}
      config={{ tension: 500, friction: 50 }}
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
    >
      {photo => ({ opacity }) => (
        <animated.div className="ctr-productImage" style={{ opacity }}>
          <div className="productImage" style={{ backgroundImage: `url(${urlForImg(photo)})` }} />
        </animated.div>
      )}
    </Trail>
  </div>
);

export default ProductImageGrid;
