import IEPolyfills from "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import QueryData from "./QueryData";
import "./styles/_index.sass";
import App from "./App";
IEPolyfills();

const Root = () => <QueryData render={allData => <App data={allData} />} />;

ReactDOM.render(<Root />, document.getElementById("root"));
