import React, { Fragment, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { ShoppingCartContext } from "../ShoppingCartLogic";
import logo from "../img/marketplaceLogo.svg";
import CartDropdown from "./CartDropdown";
import Toggle from "../Toggle";

const Nav = props => {
  const inCheckout = props.location.pathname.includes("/checkout");
  return (
    <nav>
      <div className="ctr-left">
        <Link to="/" className="ctr-appLogo">
          <img src={logo} className="appLogo" alt="logo" />
        </Link>
        <h1 className="appTitle">Excel Marketplace</h1>
      </div>
      {!inCheckout && <CartButton />}
    </nav>
  );
};

export default withRouter(Nav);

const CartButton = () => {
  const { itemsInCart, ...value } = useContext(ShoppingCartContext);
  return (
    <div className="ctr-right">
      <Toggle>
        {({ on, toggle }) => (
          <Fragment>
            <div onClick={toggle} className="btn-cart" role="button" aria-pressed="false">
              <div className="ctr-cartQtyIndicator">
                {itemsInCart.length > 0 && (
                  <div className="cartQtyIndicator">
                    <div className="cartQty">{itemsInCart.length}</div>
                  </div>
                )}
                <div
                  className="animateQty"
                  style={itemsInCart.length === 1 ? runAnimation : null}
                />
              </div>
              <svg className="material-icon" viewBox="0 0 24 24">
                <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
              </svg>
            </div>
            {on && <CartDropdown on={on} toggle={toggle} itemsInCart={itemsInCart} {...value} />}
          </Fragment>
        )}
      </Toggle>
    </div>
  );
};

const runAnimation = {
  transform: "scale(3.5)",
  opacity: 0,
  transition: "all .6s ease"
};
