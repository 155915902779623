// ie polyfill must be the first line in src/index.js
import 'react-app-polyfill/ie9';
import 'ie-array-find-polyfill';
/* eslint no-extend-native: 0 */

const IEPolyfills = function() {
  // Polyfill for IE - Object.entries
  if (!Object.entries) {
    console.log('Object.entries polyfill running');
    Object.entries = function(obj) {
      var ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];
      return resArray;
    };
  }

  // Polyfill for IE - string.prototype.includes
  if (!String.prototype.includes) {
    console.log('string.prototype.includes polyfill running');
    String.prototype.includes = function(search, start) {
      if (typeof start !== 'number') {
        start = 0;
      }
      if (start + search.length > this.length) {
        return false;
      } else {
        return this.indexOf(search, start) !== -1;
      }
    };
  }
};

export default IEPolyfills;
