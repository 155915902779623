import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { formatPrice } from "../helpers";
import { urlForImg } from "../sanityClient";

const ProductCard = props => {
  const {
    _id,
    product,
    daysRemaining,
    onSale,
    engine,
    hours,
    price,
    salePrice,
    serialNumber
  } = props.product;
  return (
    <div className="productCard">
      <Link to={`/product/${_id}`}>
        <DaysRemainingIndicator daysRemaining={daysRemaining} onSale={onSale} />
        <div className="ctr-productImage">
          <div
            className="productImage"
            role="img"
            aria-label={product}
            style={{ backgroundImage: `url(${urlForImg(props.product.photos[0])})` }}
          />
        </div>
        <div className="productInfo">
          <h3 className="specs--lg">{product}</h3>
          <p className="specs--sm">{engine}</p>
          <p className="specs--sm">{`Hours on engine: ${hours}`}</p>
          {props.loggedInAs === "admin" && <p className="specs--sm">{`Serial: ${serialNumber}`}</p>}
          <Price price={price} onSale={onSale} salePrice={salePrice} />
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;

const DaysRemainingIndicator = props => {
  const { onSale, daysRemaining } = props;
  return (
    <div className="ctr-daysRemaining">
      {onSale && <div className="willExpireIndicator" />}
      <div className="labelReveal">
        <p className="label">Days Remaining:</p>
        <div className="ctr-number">
          <p className="daysRemaining" style={{ color: `${onSale ? "#FC5F6B" : "inherit"}` }}>
            {daysRemaining}
          </p>
        </div>
      </div>
    </div>
  );
};

const Price = props => {
  const { price, onSale, salePrice } = props;
  return (
    <div className="ctr-price">
      {onSale ? (
        <Fragment>
          <div className="priceReducedIndicator" />
          <p className="specs--md price">{`${formatPrice(salePrice)}`}</p>
          <p className="specs--sm lineThrough" style={{ margin: "2px 0 0 10px" }}>
            {`${formatPrice(price)}`}
          </p>
        </Fragment>
      ) : (
        <p className="specs--md price">{`${formatPrice(price)}`}</p>
      )}
    </div>
  );
};
