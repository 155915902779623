import React, { useState } from 'react';
import { Spring, animated } from 'react-spring/renderprops';

const TermsAndConditions = props => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="wrap__termsAndConditions">
      <Spring
        native
        config={{ tension: 500, friction: 50, delay: 160 }}
        from={{ opacity: 0, y: 10 }}
        to={{ opacity: 1, y: 0 }}
      >
        {({ opacity, y }) => (
          <animated.div
            className="card infoSheet"
            style={{ opacity, transform: y.interpolate(y => `translate3d(0,${y}px,0)`) }}
          >
            <div className="accentBar" />
            <h1>Terms &amp; Conditions</h1>
            <section>
              <h4>Sales are final and are without warranty.</h4>
              <p>
                All sales on the internal Excel Marketplace are FINAL, are “AS-IS,” and are WITHOUT
                WARRANTY OF ANY KIND. WE EXPRESSLY DISCLAIM WARRANTIES OF MERCHANTABILITY AND
                FITNESS FOR A PARTICULAR PURPOSE.
              </p>
            </section>
            <section>
              <h4>Who is eligible to purchase?</h4>
              <p>
                Units shown on this website are available only to Excel employees and U.S. dealers.
                California dealers may only purchase units certified for and approved for
                California.
              </p>
            </section>
            <section>
              <h4>Disclaimer</h4>
              <p>
                The information contained herein is accurate and reliable to the best of our
                knowledge as of the date of publication; however, we do not assume any liability
                whatsoever for the accuracy and completeness of the above information.
              </p>
              <p>
                Excel Industries makes no warranties which extend beyond the description contained
                herein. Any information given in this statement does not constitute any warranty of
                merchantability or fitness for a particular use. It is the customers’ responsibility
                to inspect and to test said products in order to satisfy themselves as to the
                suitability of the products to their particular purpose. It is the customer’s
                responsibility to review the relevant product descriptions and photography prior to
                execution of an order.
              </p>
              <p>
                The customers are responsible for the appropriate, safe and legal use, processing
                and handling of our products. The information contained herein is given with the
                understanding to enlighten the prospective buyer; however, does not imply there are
                no unforeseen defects in material or workmanship. Excel Industries will not accept
                any liability with respect to the use of its products in conjunction with unsafe
                practices. Be sure to read and understand the Operator’s Manual. Copies are
                available at www.hustlerturf.com and www.bigdogmowerco.com.
              </p>
            </section>
            <label className="ctr-checkbox">
              <input type="checkbox" onChange={() => setIsChecked(!isChecked)} />
              <div className="animatedCheckbox">
                <svg width="12px" height="10px" viewBox="0 0 12 10">
                  <polyline points="1.5 6 4.5 9 10.5 1" />
                </svg>
              </div>
              <p>I have read and accept the terms</p>
            </label>
            {isChecked ? (
              <button onClick={props.continue} className="btn--primary btn--lg w--100">
                Continue
              </button>
            ) : (
              <div className="btn--disabled">Continue</div>
            )}
          </animated.div>
        )}
      </Spring>
    </div>
  );
};
export default TermsAndConditions;
